<mat-card class="card">
  @if (featured()) {
    <div class="featured-container">
      <span>New!</span>
    </div>
  }
  <mat-card-content class="content" > 
    <div class="img-container">
      <img class="card-img"[src]="image()"/>
    </div>
    <div class="content-info">
      <h3>{{ title() }}</h3>
      <p>{{ description() }}</p>
    </div>
  </mat-card-content>
</mat-card>