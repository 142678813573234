import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, inject, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { NamedSearch, SearchType } from '@sentinels/state/features/search/state';
import { APP_ROUTES } from '@shared/helpers';
import { debounceTime, map, startWith, switchMap } from 'rxjs';

import { GthAnalyticsService } from '../../../../../../../../gth-legacy/src/public-api';
import { AlgoliaService } from './services/algolia.service';

const GenericSearchList: NamedSearch = {
  type: SearchType.HELPFUL_LINKS,
  collection: [
    {
      itemName: 'View Events Near You',
      link:
        APP_ROUTES.DiscoverGames + '?context=events',
    },
    {
      itemName: 'Find People Near You',
      link:
      APP_ROUTES.DiscoverPlayers + '?context=participants',
    },
    {
      itemName: 'Discover Communities',
      link:
      APP_ROUTES.DiscoverTeams + '?context=teams',
    },
    {
      itemName: 'Create Events',
      link:
      APP_ROUTES.CreateGame,
    },
  ],
};

@Component({
  selector: 'app-omni-search',
  templateUrl: './omni-search.component.html',
  styleUrls: ['./omni-search.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatAutocompleteModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
  ], animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '100%',
          opacity: '100%',
          zIndex: 1000,
        }),
      ),
      state(
        'closed',
        style({
          width: 0,
          opacity: 0,
          visibility: 'hidden',
        }),
      ),
      transition('open => closed', [animate('.2s')]),
      transition('closed => open', [animate('.2s')]),

    ]),
    trigger('inputVis', [
      state(
        'open',
        style({
          maxWidth: '80%',
          visibility: 'visible',
        }),
      ),
      state(
        'closed',
        style({
          width: 0,
          visibility: 'hidden',
        }),
      ),
      transition('open => closed', [animate('.2s')]),
      transition('closed => open', [animate('.2s')]),
    ]),
  ],
})
export class OmniSearchComponent {
  expanded = false;
  myControl = new FormControl<string >('');
  options = [GenericSearchList];
  private router = inject(Router);
  private algoliaService = inject(AlgoliaService);
  analyticsService = inject(GthAnalyticsService);

  public searchResults =
  toSignal(
    this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      switchMap((value) => {
        // if (isDevMode) return from([results]);
        return this.algoliaService.search(value);
}),
      map((value) => value.map((group) => {
        group.hits = group.hits.slice(0, 3);
        return group;
      })),
      ));


  onAutoCompleteSelect(link: string) {
    this.router.navigateByUrl(link);
  }

  toggleSearch(trigger: MatAutocompleteTrigger, shouldBeOpen = false) {
    this.expanded = !this.expanded;
    trigger.autocomplete.panelWidth = 300;

    if (!shouldBeOpen) return;
    event.stopPropagation();
    this.analyticsService.logEvent('omni-search', 'open-panel');
    trigger.openPanel();
  }

  saveAnalytics(searchTerm: string) {
    this.analyticsService.logEvent('omni-search_see-more', searchTerm);
  }
}
