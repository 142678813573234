import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '@environments/environment';
import { GthDebugging, GthDebuggingUser } from '@gth-legacy';
import { DBUtil } from '@index/utils/db-utils';
import { GthUserModel } from '@sentinels/models';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import firebase from 'firebase/compat/app';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';

import { SrvBetaService } from './beta.service';

@Injectable({ providedIn: 'root' })
export class DebuggingService extends FirestoreService<GthDebugging> {
  protected basePath = 'user_roles';

  // A cache to avoid re-running the failed request
  private failedUserRolesCache: Set<string> = new Set();

  constructor(
    private beta: SrvBetaService,
    firestore: AngularFirestore,
  ) {
    super(firestore);
  }

  async getUsers(): Promise<GthUserModel[]> {
    const userItems = await this.firestore.collection(DBUtil.User)
      .get()
      .toPromise();
    if (userItems.empty) return [];
    const users = await userItems.docs.map(async (doc) => {
      const data = {
        uid: doc.id,
        ...doc.data() as any,
      };
      const userRoles = await this.getUserRoles(doc.id);
      const user = new GthUserModel(doc.id, data);
      const betaItems = await this.beta.getUserBetas(user.id);
      const metadata = {
        admin: userRoles.includes('admin'),
        owner: userRoles.includes('owner'),
        ambassador: userRoles.includes('ambassador'),
      };
      betaItems.forEach((b) => {
        metadata[b] = 'ON';
      });
      user.metadata = metadata;
      return user;
    });
    const allUsers = await Promise.all(users);
    return allUsers
      .filter((u) => u.displayNameFallback)
      .sort((a, b) => a.displayNameFallback.localeCompare(b.displayNameFallback));
  }

  async getUsersByRole(role: string): Promise<GthUserModel[]> {
    try {
      const userRoleRefs = await this.firestore.collection(
        this.basePath, (ref) => ref.where('role', '==', role))
        .get()
        .toPromise();
      if (userRoleRefs.empty) return [];
      const userIds = userRoleRefs.docs
        .map((r) => (r.data() as any).userId);
      const userItems = await this.firestore.collection(DBUtil.User,
        (ref) => ref.where(firebase.firestore.FieldPath.documentId(), 'in', userIds))
        .get()
        .toPromise();
      if (userItems.empty) return [];
      const users = userItems.docs.map((doc) => {
        const data = {
          uid: doc.id,
          ...doc.data() as any,
        };
        return new GthUserModel(data.id, data);
      });
      return users;
    } catch {
      return [];
    }
  }

  async getTestingUsers(): Promise<GthDebuggingUser[]> {
    try {
      const uri = this.getFunctionUri(`getTestUsers`);

      const callable = httpsCallableFromURL(getFunctions(), uri);

      return (await callable()).data as unknown as GthDebuggingUser[];
    } catch (error) {
      console.error('Error:', error);

      return [];
    }
  }

  async deleteTestingUser(id: string) {
    try {
      const uri = this.getFunctionUri(`deleteTestingUser`);
      const callable = httpsCallableFromURL(getFunctions(), uri);
      return (await callable({ id })).data as any;
    } catch (error) {
      console.error('Error:', error);

      return undefined;
    }
  }

  async grantUserRole(id: string, role: string) {
    try {
      const uri = this.getFunctionUri(`grantRole`);
      const callable = httpsCallableFromURL(getFunctions(), uri);
      (await callable({ id, role })).data as any;
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  async revokeUserRole(id: string, role: string) {
    try {
      const uri = this.getFunctionUri(`revokeRole`);
      const callable = httpsCallableFromURL(getFunctions(), uri);
      (await callable({ id, role })).data as any;
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  async sendEmail(header: string, recipientIds: string[], eventId: string) {
    try {
      const uri = this.getFunctionUri(`sendEmail`);
      const callable = httpsCallableFromURL(getFunctions(), uri);
      return (await callable({ header, recipientIds, eventId })).data as any;
    } catch (error) {
      console.error('Error:', error);

      return undefined;
    }
  }

  async sendPwaTestNotification() {
    try {
      const uri = this.getFunctionUri(`sendNotification`);
      const callable = httpsCallableFromURL(getFunctions(), uri);
      return (await callable()).data as any;
    } catch (error) {
      console.error('Error:', error);
      return undefined;
    }
  }

  async sendPushNotification(recipientId: string, message: string) {
    try {
      const uri = this.getNotificationFunctionUri(`sendPushNotification`);
      const callable = httpsCallableFromURL(getFunctions(), uri);
      return (await callable({ recipientId, message })).data as any;
    } catch (error) {
      console.error('Error:', error);
      return undefined;
    }
  }

  async getUserRoles(id: string) {
    // Check if the user is in the failed cache
    if (this.failedUserRolesCache.has(id)) {
      console.warn(`Skipping getUserRoles for user ${id} due to previous failure.`);
      return [];
    }

    try {
      const uri = this.getFunctionUri(`getRolesById`);
      const callable = httpsCallableFromURL(getFunctions(), uri);
      const response = (await callable({ id })) as any;
      return response.data as string[];
    } catch (error) {
      console.error('Error fetching roles for user', id, ':', error);
      // Add the user to the failed cache to avoid retrying
      this.failedUserRolesCache.add(id);
      return [];
    }
  }

  async getRoles() {
    try {
      const uri = this.getFunctionUri(`getRoles`);
      const callable = httpsCallableFromURL(getFunctions(), uri);
      const response = (await callable()) as any;
      return response.data as string[];
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  }

  private getFunctionUri(functionName: string) {
    const env = this.getEnviroment();
    const path = `userAdmin-triggers-${functionName}`;

    return `https://us-central1-gametimehero-${env}.cloudfunctions.net/${path}`;
  }

  private getNotificationFunctionUri(functionName: string) {
    const env = this.getEnviroment();
    const path = `notifications-triggers-${functionName}`;

    return `https://us-central1-gametimehero-${env}.cloudfunctions.net/${path}`;
  }

  private getEnviroment(): string {
    return environment.envName!;
  }
}
