import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ArkUserAvatarComponent } from '@ark';
import { GthNotificationModel } from '@sentinels/models';

@Component({
  selector: 'app-plain-text-notification',
  templateUrl: './plain-text-notification.component.html',
  styleUrls: ['./plain-text-notification.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    ArkUserAvatarComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainTextNotificationComponent {
  public notification?: GthNotificationModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {notification: GthNotificationModel}) {}
}
