<div class="layout-wrapper">
  <app-top-nav-bar
    #topNavBar
    [numUnreadMessages]="numUnreadMessages$ | async"
    [loadingUser]="userIsLoading()"
    [notifications]="notifications()"
    [isAdminOrOwner]="isAdminOrOwner()"
    [user]="user()"
    [showSelf]="showTopBar"
    (logout)="onLogout()"
    (login)="onLogin()"
  />

  <div
    class="content-wrapper app-scrolling-content"
    #contentWrapper
    [class.full-width]="navBarOptions.fullWidth"
  >
    <ng-content />
  </div>

  <app-bottom-nav-bar
    #bottomBar
    ngx-sticky-boundary
    [user]="user()"
    [showSelf]="showBottomBar"
    [loadingUser]="userIsLoading()"
  >
    <ng-content select="[slot=fab]" />
  </app-bottom-nav-bar>
</div>
