@defer (when user()) {
  <div class="buttons-container">
    <mat-button-toggle-group #group="matButtonToggleGroup" name="notificationStatus"
      aria-label="Notification Status" [value]="filter()">
      <mat-button-toggle value="unread" (click)="updateFilter('unread')">Unread
        ({{ unreadNotificationCount() }})</mat-button-toggle>
      <mat-button-toggle value="read" (click)="updateFilter('read')">Read ({{ readNotificationCount() }})</mat-button-toggle>
    </mat-button-toggle-group>

    @if (filter() === 'unread' && unreadNotifications().length > 0) {
      <button
        mat-button
        class="mark-all-read-btn"
        [disabled]="loadingNotifications || !hasNotifications"
        (click)="onReadAllBtnClick()"
      >Mark all as read</button>
    }
  </div>

  @if (hasNotifications()) {
    <ul class="notifications">
      @for (notification of (
        filter() === 'read' ?
        readNotifications() :
        unreadNotifications()
      ); track notification.id) {
        <li class="notification">
          <app-notification
            [notification]="notification"
            [loadingNotifications]="loadingNotifications"
            (notificationClick)="onNotificationClick(notification, user())"
            (notificationDelete)="onNotificationDeleteButtonClick(notification)"
          ></app-notification>
        </li>
      }
    </ul>
  }
} @loading {
  <div class="fx-auto fx-centered">
    <mat-spinner [diameter]="36"></mat-spinner>
  </div>
}

@if (!hasNotifications()) {
  <div class="spacer empty-notifications">
    <h3>No notifications yet</h3>
    <p>
      You're all caught up! Now, put your device down and go play sports.
    </p>
  </div>
}

<button mat-stroked-button color="primary" class="clear-all-btn" (click)="onClearAllBtnClick()"
  [disabled]="loadingNotifications || !hasNotifications">Clear All</button>
