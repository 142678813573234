import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'gth-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgClass,
    MatRipple,
  ],
})
export class GthFeedbackDialogComponent {
  feedback = '';
  selectedRating?: number;
  feedbackType: 'bug' | 'request' | 'general' = 'general';
  ratings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(private dialogRef: MatDialogRef<GthFeedbackDialogComponent>) {}

  onSubmitButtonClick() {
    this.dialogRef.close({
      feedback: this.feedback,
      rating: this.selectedRating,
      feedbackType: this.feedbackType,
    });
  }

  onCancelButtonClick() {
    this.dialogRef.close(undefined);
  }
}
