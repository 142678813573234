import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostListener,
  input,
  OnInit,
  output,
  signal,
  TemplateRef,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { ArkUserAvatarComponent } from '@ark';
import { ArkInfoCardComponent } from '@ark/components/info-card/info-card.component';
import { GthAnalyticsService, GthFeedbackService, GthRemoteConfigService } from '@gth-legacy';
import { GthNotificationModel, GthUserModel } from '@sentinels/models';
import { AvatarComponentModule } from '@shared/dialogs/avatar/avatar.component';
import { APP_ROUTES } from '@shared/helpers';
import { AppsMenuComponent } from '@shared/layouts/nav-bar-layout/components/apps-menu/apps-menu.component';
import {
  DISCOVER_MENU,
  PROFILE_MENU,
} from '@shared/layouts/nav-bar-layout/views/nav-bar.component';
import { AppSidenavService } from '@shared/services/sidenav.service';

import { NotificationsSidenavComponent } from '../notifications-sidenav/notifications-sidenav.component';
import { OmniSearchComponent } from '../omni-search/omni-search.component';

export interface NavBavOptions {
  pageName?: string;
}

export interface DropDownMenuItem {
  title: string;
  description: string;
  image: string;
  url: string;
  featured?: boolean;
  params?: { [key: string]: string };
  fragment?: string;
  matIcon: string;
}

export const createDropdown: DropDownMenuItem[] = [
  {
    title: 'Plan Your Event',
    description: 'Bring your friends together for fun and competition!',
    image: 'assets/nav-01.webp',
    url: APP_ROUTES.CreateGame,
    matIcon: 'event', // icon for events and planning
  },
  {
    title: 'Start a New Community',
    description: 'Build teams, groups, leagues, or clubs for endless fun!',
    image: 'assets/nav-02.webp',
    url: APP_ROUTES.CreateTeam,
    matIcon: 'groups', // icon for teams and communities
  },
  {
    title: 'Add Your Favorite Spot',
    description: 'Share your go-to place for fun and activities!',
    image: 'assets/nav-04.webp',
    url: APP_ROUTES.PlacesNew,
    matIcon: 'place', // icon for locations and spots
  },
  {
    title: 'Collect Payments Easily',
    description: 'Quickly send invoices for your group activities!',
    image: 'assets/nav-03.webp',
    url: APP_ROUTES.Invoices,
    featured: true,
    matIcon: 'payments', // icon for payments and invoicing
  },
  {
    title: 'Create Waivers and Forms With Ease',
    description: 'Streamline your paperwork—build forms in minutes!',
    image: 'assets/logo-standing-fullcolor.webp',
    url: APP_ROUTES.Forms,
    featured: true,
    matIcon: 'post_add', // icon for forms and waivers
  },
];

export const findDropdown: DropDownMenuItem[] = [
  {
    title: 'Join a New Community',
    description: 'Connect with groups who love your favorite activities!',
    image: 'assets/nav-05.webp',
    url: APP_ROUTES.DiscoverTeams,
    matIcon: 'group_add',
    params: { context: 'communities' },
  },
  {
    title: 'Discover a Cool Spot',
    description: 'Find the perfect place to play, hang, & enjoy activities!',
    image: 'assets/nav-06.webp',
    url: APP_ROUTES.DiscoverPlaces,
    matIcon: 'location_on',
    params: { context: 'places' },
  },
  {
    title: 'Find a Partner or Sub',
    description: 'Need a teammate, sub, or new friend? Find one now!',
    image: 'assets/nav-01.webp',
    url: APP_ROUTES.DiscoverPlayers,
    matIcon: 'person_search',
    params: { context: 'participants' },
  },
  {
    title: 'Search for an Event to Join',
    description: 'Lock in your next big game or friendly meetup!',
    image: 'assets/nav-08.webp',
    url: APP_ROUTES.DiscoverGames,
    matIcon: 'sports_esports',
    params: { context: 'events' },
  },
];

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    AvatarComponentModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ArkUserAvatarComponent,
    NotificationsSidenavComponent,
    AppsMenuComponent,
    NgTemplateOutlet,
    OmniSearchComponent,
    ArkInfoCardComponent,
    NgClass,
  ],
})
export class TopNavBarComponent implements OnInit {
  logout = output();
  login = output();
  showSelf = input(true);
  numUnreadMessages = input<number>(); // todo: change
  loadingUser = input(true);
  notifications = input<{ [key: string]: GthNotificationModel }>({});
  isAdminOrOwner = input(false);
  user = input.required<GthUserModel>();
  isMenuActive = signal(false);
  activeDropdown: DropDownMenuItem[];
  createDropdown = createDropdown;
  findDropdown = findDropdown;
  notificationsEnabled = false;

  notificationCount = computed(
    () => Object.values(this.notifications()).filter((n) => !n.read).length,
  );
  filteredNotifications = computed(() => {
    return Object.values(this.notifications()).reduce(
      (acc, n) => {
        if (n.read) {
          acc.read.push(n);
        } else {
          acc.unread.push(n);
        }
        return acc;
      },
      { read: [], unread: [] },
    );
  });
  hasUnreadNotifications = computed(() => this.filteredNotifications().unread.length > 0);
  hasNotifications = computed(() => Object.values(this.notifications()).length > 0);

  protected readonly APP_ROUTES = APP_ROUTES;
  protected readonly PROFILE_MENU = PROFILE_MENU;
  protected readonly DISCOVER_MENU = DISCOVER_MENU;

  notificationTime: number;

  constructor(
    private sidenav: AppSidenavService,
    private feedback: GthFeedbackService,
    private analyticsService: GthAnalyticsService,
    private remoteConfig: GthRemoteConfigService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMenuActive.set(false);
  }

  async ngOnInit() {
    this.notificationTime = new Date().getTime();
    this.notificationsEnabled = await this.remoteConfig.getNotificationsEnabled();
  }

  onNotificationMenuClick(notifications: TemplateRef<unknown>) {
    this.sidenav.ctrl.set({
      title: 'Notifications',
      portalContent: notifications,
    });
  }

  async onAuthBtnClick(user?: GthUserModel) {
    if (user) this.logout.emit();
    else this.login.emit();
  }

  onFeedbackButtonClick() {
    this.feedback.openFeedbackDialog();
  }

  toggleClassForDropdownType(dropDownToMakeActive: DropDownMenuItem[]) {
    const jsonInput = JSON.stringify(dropDownToMakeActive);
    const jsonActive = JSON.stringify(this.activeDropdown);

    if (jsonInput === jsonActive || !this.isMenuActive()) {
      this.isMenuActive.set(!this.isMenuActive());
    }
    this.activeDropdown = dropDownToMakeActive;
  }

  analyticsClick(title: string) {
    this.analyticsService.logEvent('Top Nav Bar', title);
  }
}
