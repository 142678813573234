<div class="wrapper">
  <div class="content">
    <div class="actor">
      <ark-user-avatar [src]="data?.notification?.userModel?.photoURL ?? ''" />
      <p>{{ data?.notification?.userModel?.displayNameFallback }}</p>
    </div>
    <p>{{ data?.notification?.text }}</p>
  </div>
  <div class="actions">
    <div class="spacer"></div>
    <button mat-button class="btn uppercase" mat-dialog-close>
      Close
    </button>
  </div>
</div>