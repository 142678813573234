import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { APP_ROUTES } from '@shared/helpers';
import { StartsWithPipe } from '@shared/pipes/starts-with.pipe';

export interface MenuItems {
  name: string;
  icon: string;
  routerLink: string[];
  disabled: boolean;
}

@Component({
  selector: 'app-apps-menu',
  templateUrl: './apps-menu.component.html',
  styleUrls: ['./apps-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    MatIconModule,
    StartsWithPipe,
    MatButtonModule,
    RouterLinkActive,
    MatTooltipModule,
  ],
})
export class AppsMenuComponent {
  protected readonly menuItems: MenuItems[] = [
    {
      name: 'My Communities',
      icon: 'group',
      routerLink: [APP_ROUTES.MyCommunities],
      disabled: false,
    },
    {
      name: 'Profile',
      icon: 'person',
      routerLink: [APP_ROUTES.Profile],
      disabled: false,
    },
    {
      name: 'My Score Keeper',
      icon: 'scoreboard',
      routerLink: [],
      disabled: true,
    },
    {
      name: 'My Settings',
      icon: 'settings',
      routerLink: [APP_ROUTES.Settings],
      disabled: false,
    },
    {
      name: 'Invoices',
      icon: 'payments',
      routerLink: [APP_ROUTES.Invoices],
      disabled: false,
    },
  ];
  private appsMenuOpenedSignal = signal(false);
  appsMenuOpened = this.appsMenuOpenedSignal.asReadonly();

  toggleAppsMenu() {
    this.appsMenuOpenedSignal.set(!this.appsMenuOpened());
  }
}
