import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, inject, Input, Output, TemplateRef, ViewChild, viewChild } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { GthUserModel } from '@sentinels/models';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { NgxHideOnScrollModule } from '@shared/directives/hide-on-scroll/ngx-hide-on-scroll.module';
import { APP_ROUTES } from '@shared/helpers';
import { SmallIconProperties, SmallNavIconComponent } from '@shared/layouts/nav-bar-layout/components/small-nav-icon/small-nav-icon.component';
import { MenuItemAuthCheckPipe } from '@shared/pipes/menu-item-auth-check.pipe';
import { BehaviorSubject } from 'rxjs';

import { GthAnalyticsService } from '../../../../../../../../gth-legacy/src/public-api';
import { createDropdown, DropDownMenuItem, findDropdown } from '../top-nav-bar/top-nav-bar.component';

const manageDropdown: DropDownMenuItem[] = [
  {
    title: 'My Communities',
    description: 'Manage your teams, groups, and leagues all in one place!',
    image: 'assets/nav-manage-01.png',
    matIcon: 'group',
    url: APP_ROUTES.MyCommunities,
  },
  {
    title: 'My Events',
    description: 'View and organize your upcoming events and activities.',
    image: 'assets/nav-manage-02.png',
    matIcon: 'games',
    url: APP_ROUTES.Profile,
    fragment: 'upcoming',
  },
  {
    title: 'My Availability',
    description: 'Set and update your schedule for events and activities.',
    image: 'assets/nav-manage-03.png',
    matIcon: 'schedule',
    url: APP_ROUTES.Settings,
    params: { tab: 'availability' },
  },
];

@Component({
  selector: 'app-bottom-nav-bar',
  templateUrl: './bottom-nav-bar.component.html',
  styleUrls: ['./bottom-nav-bar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    RouterModule,
    NgxHideOnScrollModule,
    SmallNavIconComponent,
    MatRippleModule,
    MenuItemAuthCheckPipe,
    MatBottomSheetModule,
    MatListModule,
    MatTooltipModule,
  ],
})
export class BottomNavBarComponent implements AfterViewInit {
  @Input()
  user: GthUserModel;
  MatBotomSheetModule: any;


  @Input()
  loadingUser: boolean;

  @Input()
  showSelf: boolean;

  @Output()
  shown = new EventEmitter<boolean>();

  @ViewChild('bottomBar', { read: ElementRef }) bottomBar: ElementRef;
  activeMenu = createDropdown;

  private _bottomSheet = inject(MatBottomSheet);

  offset = 0;
  isHidden = new BehaviorSubject(true);
  translateY$ = new BehaviorSubject('translateY(100%)');
  APP_ROUTES = APP_ROUTES;
  hasContent = false;
  barShown = true;
  createDropdown = createDropdown;
  findDropdown = findDropdown;
  manageDropdown =manageDropdown;
  analytics = inject(GthAnalyticsService);

  iconProps: SmallIconProperties[] = [
    {
      name: 'Home',
      url: APP_ROUTES.Home,
      image: 'home',
      fragment: 'profile-personality',
    },

    {
      name: 'Find',
      url: APP_ROUTES.DiscoverGames,
      image: 'discover',
    },

    {
      name: 'Create',
      url: APP_ROUTES.CreateGame,
      image: 'add',
    },

    {
      name: 'Manage',
      url: APP_ROUTES.Profile,
      image: 'user',
      fragment: 'profile-passions',
    },

    {
      name: 'Messages',
      url: APP_ROUTES.Messages,
      image: 'messages',
    },
  ];

  constructor(
    public ref: ElementRef,
    private router: Router,
    private safeWindow: SrvSafeWindowService,
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this._bottomSheet.dismiss();
  }

  ngAfterViewInit(): void {
    this.hasContent = this.ref.nativeElement.children.length > 0;
  }

  isCurrentRouteSignUpOrLogin(): boolean {
    const currentRoute = this.router.url;
    return currentRoute === APP_ROUTES.SignUp || currentRoute === APP_ROUTES.Login ||
      currentRoute === APP_ROUTES.MoreInfo || currentRoute === APP_ROUTES.ForgotPassword;
  }

  isCurrentRouteDiscover(): boolean {
    const currentRoute = this.router.url;
    const discoverRoutes = [
      APP_ROUTES.DiscoverGames,
      APP_ROUTES.DiscoverPlayers,
      APP_ROUTES.DiscoverTeams,
    ];
    return discoverRoutes.includes(currentRoute);
  }

  isCurrentRouteManage(): boolean {
    const currentRoute = this.router.url;
    const manageRoutes = [
      APP_ROUTES.Profile,
      APP_ROUTES.Teams,
      APP_ROUTES.Settings,
    ];
    return manageRoutes.includes(currentRoute);
  }

  isDisplayed() {
    return this.getStyle().display !== 'none';
  }

  getStyle() {
    const element = this.bottomBar.nativeElement;
    return this.safeWindow.getComputedStyle(element);
  }

  getOffset() {
    const style = this.getStyle();
    const height = parseFloat(style.height);
    const translateY = parseFloat(style.transform?.split(',')[5]);
    return isNaN(translateY) ? height : height - translateY;
  }

  onVisibilityChange(val: boolean) {
    this.barShown = val;
    this.isHidden.next(!val);
    this.shown.emit(val);
  }

  openBottomSheet(template:TemplateRef<any>, menu: DropDownMenuItem[]) {
  this.activeMenu = menu;

   this._bottomSheet.open(template);
  }

  onBottomSheetClick(title: string) {
    this.analytics.logEvent('BottomNavBar', title);
    this._bottomSheet.dismiss();
  }
}
