<div class="search-container">
  <button
    mat-icon-button
    (click)="toggleSearch(trigger, true)"
    class="search-btn">
    <mat-icon color="primary">search</mat-icon>
  </button>
  <div
    class="search-area"
    [@openClose]="expanded ? 'open' : 'closed'">
    <button
      matPrefix
      mat-icon-button
      (click)="toggleSearch(trigger)"
      class="search-btn back-btn">
      <mat-icon class="icon" color="accent">close</mat-icon>
    </button>
    <mat-form-field
      [@inputVis]="expanded ? 'open' : 'closed'"
      subscriptSizing="dynamic"
      appearance="outline">
      <input
        type="text"
        matInput
        [formControl]="myControl"
        #trigger="matAutocompleteTrigger"
        [matAutocomplete]="autoGroup"
        placeholder="Search Gametime Hero">
      <mat-autocomplete #autoGroup="matAutocomplete">
        @for (group of searchResults(); track group) {
          <mat-optgroup [label]="group.name">
            @for (item of group.hits; track item.id || item) {
              <mat-option
                [value]="item.displayName || item.title"
                (click)="onAutoCompleteSelect(group.link(item?.id ||item?.uid  || item.objectID))">
                @switch (group.name) {
                  @case ('Users') {
                    <mat-card class="option-card" appearance="outlined">
                      <mat-card-header>
                        @if(item.photoURL){
                          <img mat-card-avatar mat-card-image src="{{item.photoURL}}" alt="Photo of User">
                        }@else {
                          <mat-icon mat-card-avatar>person</mat-icon>
                        }
                        <mat-card-title>{{item.displayName || item.title}}</mat-card-title>
                        <mat-card-subtitle>{{item.defaultCity?.name}}</mat-card-subtitle>
                      </mat-card-header>
                    </mat-card>
                  }@case('Events'){
                    <mat-card class="option-card" appearance="outlined">
                      <mat-card-header>
                        @if(item.banner){
                          <img mat-card-avatar mat-card-sm-image src="{{item.banner}}" alt="Event Photo">
                        }@else {
                          <mat-icon mat-card-avatar>event</mat-icon>
                        }
                        <mat-card-title>{{item.title}}</mat-card-title>
                        <mat-card-subtitle>{{item.location?.name}}</mat-card-subtitle>
                      </mat-card-header>
                    </mat-card>
                  }@case('Communities'){
                    <mat-card class="option-card" appearance="outlined">
                      <mat-card-header>
                        @if(item.photoURL){
                          <img mat-card-avatar mat-card-sm-image src="{{item.photoURL}}" alt="Event Photo">
                        }@else {
                          <mat-icon mat-card-avatar>group</mat-icon>
                        }
                        <mat-card-title>{{item.name}}</mat-card-title>
                        <mat-card-subtitle>{{item.location?.name}}</mat-card-subtitle>
                      </mat-card-header>
                    </mat-card>
                  }
                }

              </mat-option>
            }
          </mat-optgroup>
          <div class="link-div">
            <a
              (click)="saveAnalytics(group.name)"
              class="link"
              [queryParams]="{
                  query: myControl.value,
                  type: group.name
              }"
              [routerLink]="['/full-search']">See More..</a>
          </div>
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
