
export enum SearchType{
  EVENTS = 'Events',
  COMMUNITIES = 'Communities',
  PEOPLE = 'People',
  HELPFUL_LINKS = 'Helpful Search Links'
}

export interface NamedSearch {
  type?: SearchType;
  collection: SearchItem[];
}

export interface SearchItem {
  itemName: string;
  link: string
}

export interface SearchState {
  isSearching?: boolean,
}


