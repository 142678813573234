import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationExtras, Router } from '@angular/router';
import { NotificationType } from '@index/enums';
import { GthNotificationModel, GthUserModel } from '@sentinels/models';
import { TeamRosterService } from '@sentinels/services/firebase/team-roster.service';
import { EventRatingComponent } from '@shared/dialogs/event-rating/event-rating.component';
import { NewBadgeNotificationComponent } from '@shared/dialogs/new-badge-notification/new-badge-notification.component';
import {
  EditPaymentDialogResponseType,
  NotificationDialogComponent,
} from '@shared/dialogs/notification-dialog/notification-dialog.component';
import { PlainTextNotificationComponent } from '@shared/dialogs/plain-text-notification/plain-text-notification.component';
import { APP_ROUTES } from '@shared/helpers';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationDialogService {
  private dialog = inject(MatDialog);
  private snackbar = inject(MatSnackBar);
  private teamRosterService = inject(TeamRosterService);
  private router = inject(Router);

  openNotificationDialog(notification: GthNotificationModel, user: GthUserModel) {
    switch (notification.type) {
      /** Event Notifications */
      case NotificationType.EVENT_JOINER_PENDING_CREATOR:
      case NotificationType.EVENT_JOINER_APPROVED:
      case NotificationType.EVENT_JOINER_DROPPED:
      case NotificationType.EVENT_CANCELLED:
      case NotificationType.EVENT_JOINER_PENDING_JOINER:
        this.openEventJoinerDialog(notification);
        break;
      /** Team Notifications */
      case NotificationType.TEAM_REMINDER:
      case NotificationType.TEAM_JOINER_PENDING_CREATOR:
      case NotificationType.TEAM_JOINER_PENDING_JOINER:
      case NotificationType.TEAM_JOINER_APPROVED:
      case NotificationType.TEAM_JOINER_DENIED:
      case NotificationType.TEAM_JOINER_DROPPED:
        this.openTeamJoinerDialog(notification);
        break;
      case NotificationType.NEW_BADGE:
        this.openNewBadgeDialog(notification);
        break;
      case NotificationType.RATE_PLAYER:
        this.openRatePlayerDialog(notification, user);
        break;
      case NotificationType.RATE_HOST:
        this.openRateHostDialog(notification);
        break;
      case NotificationType.TEAM_ANNOUNCEMENT:
        this.openTeamAnnouncemenDialog(notification, user);
        break;
      case NotificationType.NEW_CONNECTION:
      case NotificationType.ENDORSE_PLAYER:
        this.router.navigate([APP_ROUTES.Profile]);
        break;
      case NotificationType.PLAIN_TEXT:
        this.openPlainTextDialog(notification);
        break;
      case NotificationType.TEAM_NEW_MEMBER:
        this.router.navigate([APP_ROUTES.Teams, notification.team, 'roster']);
        break;
      case NotificationType.NEW_MESSAGE:
        this.router.navigate([APP_ROUTES.Teams, notification.team, 'announcements']);
        break;
    }
  }

  private openTeamAnnouncemenDialog(notification: GthNotificationModel, user: GthUserModel) {
    this.dialog.open(NotificationDialogComponent, {
      id: 'team-announcement-notification-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
      data: { notification, user },
    });
  }

  private openTeamJoinerDialog(notification: GthNotificationModel) {
    const dialog = this.dialog.open(NotificationDialogComponent, {
      id: 'team-joiner-notification-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
      data: { notification },
    });

    dialog.afterClosed().subscribe(async (result) => {
      if (!result) return;
      const responseType = result.responseType;
      const userId = notification.joiner;
      const user = notification.joinerModel;
      const team = notification.teamModel;

      switch (responseType) {
        case EditPaymentDialogResponseType.Approve:
          if (!team || !userId) return;
          const successAddingPlayer = await this.teamRosterService.updateRoleByUserId(
            team.id,
            userId,
            'Member',
          );
          if (!successAddingPlayer) {
            this.snackbar.open('Something went wrong joining team');
            return;
          }
          const snackbar = this.snackbar.open('Successfully joined team', 'View Team');

          snackbar
            .onAction()
            .pipe(take(1))
            .forEach(() => {
              if (!team) return;
              const teamId = team.id;
              this.router.navigate([APP_ROUTES.Teams, teamId, 'dashboard']);
            });
          break;
        case EditPaymentDialogResponseType.Disapprove:
          if (!team || !userId) return;
          team.denyPlayer(userId);
          const successRemovingPlayer = await this.teamRosterService.removePlayerByUserId(
            team.id,
            userId,
          );
          this.snackbar.open(
            successRemovingPlayer
              ? 'Successfully disapproved participant'
              : 'Something went wrong disapproving participant',
          );
          break;
        case EditPaymentDialogResponseType.Message:
          const navExtras: NavigationExtras = {
            queryParams: {
              userId: user?.uid ?? '',
            },
          };
          this.router.navigate([APP_ROUTES.Messages], navExtras);
          break;
      }
    });
  }

  private openEventJoinerDialog(notification: GthNotificationModel) {
    const dialog = this.dialog.open(NotificationDialogComponent, {
      id: 'event-joiner-notification-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
      data: { notification },
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result) return;
      const responseType = result.responseType;
      const userId = notification.joiner;
      const user = notification.joinerModel;
      const eventItemId = notification.eventItem;
      switch (responseType) {
        case EditPaymentDialogResponseType.Approve:
          if (!userId || !eventItemId) return;
          // TODO: Implement this
          // this.subscriptions.add(
          //   this.cloudFunctionService.eventJoiner
          //     .approvePlayer$(userId, eventItemId).subscribe(),
          // );
          break;
        case EditPaymentDialogResponseType.Disapprove:
          if (!userId || !eventItemId) return;
          // TODO: implement this
          // this.subscriptions.add(
          //   this.cloudFunctionService.eventJoiner
          //     .denyPlayer$(userId, eventItemId).subscribe(),
          // );
          break;
        case EditPaymentDialogResponseType.Message:
          if (notification.type === NotificationType.EVENT_JOINER_PENDING_JOINER) {
            if (notification.eventModel) {
              this.router.navigate([APP_ROUTES.DiscoverGames, notification.eventModel.id]);
            }
            return;
          }
          const navExtras: NavigationExtras = {
            queryParams: {
              userId: user?.uid ?? '',
            },
          };
          this.router.navigate([APP_ROUTES.Messages], navExtras);
          break;
      }
    });
  }

  private openNewBadgeDialog(notification: GthNotificationModel) {
    this.dialog.open(NewBadgeNotificationComponent, {
      id: 'new-badge-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog',
      data: { badge: notification.badgeModel },
    });
  }

  private openPlainTextDialog(notification: GthNotificationModel) {
    this.dialog.open(PlainTextNotificationComponent, {
      id: 'plain-text-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      data: { notification },
      width: '450px',
      minHeight: '250px',
    });
  }

  private openRatePlayerDialog(notification: GthNotificationModel, user: GthUserModel) {
    const game = notification.eventModel;
    if (!game?.participants?.length) {
      this.snackbar.open('Event had no participants to rate.', '', { duration: 5000 });
      return;
    }
    const dialog = this.dialog.open(EventRatingComponent, {
      id: 'rate-event-players-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      width: '500px',
      height: '750px',
      data: {
        user,
        game,
      },
    });

    dialog.afterClosed().subscribe((response) => {
      if (response) {
        switch (response.note) {
          case 'report':
            // TODO: Report the Player
            break;
          case 'submit':
            // TODO
            if (!notification.userModel || !user) return;
            const ratings = response.ratingsSelection;
            notification.userModel.addRatings(user.model, ratings);
            // TODO: Implement this
            // this.subscriptions.add(
            //   this.cloudFunctionService.user.update$(notification.userModel).subscribe(),
            // );
            break;
        }
        this.snackbar.open('Thank you for your feedback');
      }
    });
  }

  private openRateHostDialog(_notification: GthNotificationModel) {
    // TODO: Handle this in the future when we have a use case
  }
}
