import { NgOptimizedImage } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    NgOptimizedImage,
    MatBadgeModule,
  ],
  selector: 'ark-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class ArkInfoCardComponent{
  title = input<string>('Test');
  description = input<string>('Test');
  image = input<string>('event');
  featured = input<boolean>(false);
}
