@if (hasContent) {
<div class="fab-container" [class.--above-bar]="barShown">
  <ng-content></ng-content>
</div>
}

<mat-toolbar class="bottom-bar" *ngIf="!isCurrentRouteSignUpOrLogin()" #bottomBar appHideOnScroll [propertyUsedToHide]="'transform'"
  [showAtOrBelow]="30" [valueWhenHidden]="(translateY$ | async)" [valueWhenShown]="'translateY(0%)'"
  classNameWhenHidden="hidden"
  (visibilityChange)="onVisibilityChange($event)">
  @for (prop of iconProps; track prop.name) {
    @switch (prop.name) {
      @case ('Find') {
        <button 
          matRipple
          (click)="openBottomSheet(bottomSheet, findDropdown)"
          class="discover link"
          [class.active]="isCurrentRouteDiscover()"
        >
          <app-small-nav-icon [iconProps]="prop"></app-small-nav-icon>
        </button>
      }
      @case ('Manage') {
        <button 
          matRipple
          class="discover link"
          (click)="openBottomSheet(bottomSheet, manageDropdown)"
          [class.active]="isCurrentRouteManage()"
        >
          <app-small-nav-icon [iconProps]="prop"></app-small-nav-icon>
        </button>
      }
      @case ('Create') {
        <button
          matRipple
          (click)="openBottomSheet(bottomSheet, createDropdown)"
          class="create link">
          <mat-icon>{{ prop.image }}</mat-icon>
        </button>
      }
      @default {
        <a 
          matRipple 
          [routerLink]="prop.url"
          routerLinkActive="active"
          [fragment]="prop.fragment"
          [queryParams]="prop.params"
          class="no-dec"
        >
          <app-small-nav-icon [iconProps]="prop"></app-small-nav-icon>
        </a>
      }
    }
  }
</mat-toolbar>

<ng-template #bottomSheet>
  <mat-nav-list>
      @for (item of activeMenu; track item.url) {
        <a 
          class="bottom-sheet-link"
          (click)="onBottomSheetClick(item.title)"
          [routerLink]="item.url"
          [queryParams]="item.params"
          [fragment]="item.fragment"
          routerLinkActive="active">
        <mat-list-item>
          <mat-icon matListItemIcon>{{item.matIcon}}</mat-icon>
          <div matListItemTitle class="list-item-title">{{item.title}}</div>
          <div matListItemLine class="list-item-line">
              {{item.description}}
          </div>
          @if(item.featured) {
            <span matListItemMeta class="featured">New!</span>
          }
          <br/>
        </mat-list-item>
        </a>
        <mat-divider></mat-divider>
      }
  </mat-nav-list>
</ng-template>