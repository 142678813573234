import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

type BetaItem = { betaItem: string, userId: string };

@Injectable({ providedIn: 'root' })
export class SrvBetaService {
  constructor(
    private firestore: AngularFirestore,
  ) { }

  async getUserBetas(userId: string) {
    const userItems = await this.firestore.collection('beta_users',
      (ref) => ref.where('userId', '==', userId))
      .get()
      .toPromise();

    if (userItems.empty) return [];
    return userItems.docs
      .map((d) => (d.data() as unknown as BetaItem).betaItem);
  }

  async addUserBeta(userId: string, betaItem: string) {
    // Check if user has beta already
    const betaItems = await this.getUserBetas(userId);
    const existing = betaItems.find((b) => b === betaItem);
    if (existing) return true;

    try {
      await this.firestore.collection('beta_users').add({
        userId,
        betaItem,
      });
      return true;
    } catch {
      return false;
    }
  }

  async removeUserBeta(userId: string, betaItem: string) {
    try {
      const userItems = await this.firestore.collection<BetaItem>('beta_users',
        (ref) => ref.where('userId', '==', userId))
        .get()
        .toPromise();
      if (userItems.empty) return false;
      const revokedItem = userItems.docs.find((b) => b.data().betaItem === betaItem);
      if (revokedItem) {
        this.firestore.doc(`beta_users/${revokedItem.id}`).ref.delete();
        return true;
      }
    } catch {
      return false;
    }
    return false;
  }
}
