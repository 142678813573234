@if (showSelf()) {
<mat-toolbar class="toolbar">
  <mat-toolbar-row class="top-row">
    <!-- Large Screen Items -->
    <div class="large-screen-container">
      <!-- Logo -->
      <a
        class="header-home-link"
        [routerLink]="[user() ? APP_ROUTES.Home : APP_ROUTES.SignUp]"
      >
        <img src="assets/logo-g-small.webp" class="logo" />
      </a>
    </div>

    <div class="center-container">
      <!-- Discover -->
      @if(user()){
      <a
        [hidden]="!user()"
        class="link messages left center drop"
        href="javascript:;"
        (click)="toggleClassForDropdownType(createDropdown)"
        routerLinkActive="active"
      >
        <div
          class="nav-text-container"
          [ngClass]="{
            'nav-text-active': activeDropdown === createDropdown && isMenuActive()
          }"
        >
          <p class="nav-content-wrapper">
            <span class="nav-text">Create</span>
          </p>
          <mat-icon class="dropdown-icon">
            {{
              activeDropdown === createDropdown && isMenuActive()
                ? 'arrow_drop_up'
                : 'arrow_drop_down'
            }}
          </mat-icon>
        </div>
      </a>
      }
      <a
        [hidden]="!user()"
        class="link messages left center drop"
        href="javascript:;"
        (click)="toggleClassForDropdownType(findDropdown)"
        routerLinkActive="active"
      >
        <div
          class="nav-text-container"
          [ngClass]="{ 'nav-text-active': activeDropdown === findDropdown && isMenuActive() }"
        >
          <p class="nav-content-wrapper">
            <span class="nav-text">Find</span>
          </p>
          <mat-icon class="dropdown-icon">
            {{
              activeDropdown === findDropdown && isMenuActive()
                ? 'arrow_drop_up'
                : 'arrow_drop_down'
            }}
          </mat-icon>
        </div>
      </a>

      <a
        class="link messages left center drop"
        href="https://www.gametimehero.com/blog"
        routerLinkActive="active"
      >
        <div class="nav-text-container">
          <p class="nav-content-wrapper">
            <span class="nav-text">Learn</span>
          </p>
        </div>
      </a>
    </div>

    <div class="large-screen-container right-btns">
      <app-omni-search />

      <!-- Messages -->
      <a class="link messages" [routerLink]="APP_ROUTES.Messages" routerLinkActive="active">
        <mat-icon
          [matBadge]="numUnreadMessages()"
          [matBadgeHidden]="!numUnreadMessages()"
          aria-hidden="false"
          >message</mat-icon
        >
      </a>

      <!-- Notifications -->
      <ng-container [ngTemplateOutlet]="notificationsBtnTmpl" />

      <!-- Avatar -->
      <ng-template [ngTemplateOutlet]="avatarTemplate" />
    </div>

    <!-- Small Screen Items -->
    <div class="small-screen-container">
      <div class="small-left">
        <a
          class="header-home-link"
          [routerLink]="[user() ? APP_ROUTES.Home : APP_ROUTES.SignUp]"
        >
          <img src="assets/logo-g-small.webp" alt="" class="logo" />
        </a>
      </div>

      <div class="small-right">
        <!-- Avatar -->
        <ng-template class="avatar-template" [ngTemplateOutlet]="avatarTemplate" />

        <!-- Notifications -->
        <ng-container
          class="notifications-template"
          [ngTemplateOutlet]="notificationsBtnTmpl"
        />

        <app-omni-search />
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row [ngClass]="{ active: isMenuActive() }" class="bottom-row !z-[10000]">
    <div class="contents">
      @for (link of activeDropdown; track link.url) {
      <a
        class="nav-card"
        (click)="analyticsClick(link.title)"
        [queryParams]="link.params"
        [routerLink]="link.url"
      >
        <ark-info-card
          [featured]="link.featured"
          [title]="link.title"
          [image]="link.image"
          [description]="link.description"
        />
      </a>
      }
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Discover Menu -->
<mat-menu #discoverMatMenu="matMenu" class="discover-menu">
  @for (item of DISCOVER_MENU; track item.label) { @if (!item.disabled) {
  <a
    mat-menu-item
    class="link"
    routerLinkActive="active"
    [routerLink]="item.routerLink"
    [disabled]="(item.authNeeded ? user() === undefined : false) || item.disabled"
  >
    @if (item.icon.startsWith('gth')) {
    <mat-icon [svgIcon]="item.icon" />
    } @else {
    <mat-icon [fontIcon]="item.icon" />
    }
    <span class="link-text">{{ item.label }}</span>
  </a>
  } }
</mat-menu>

<!-- Notifications -->
<ng-template #notificationsBtnTmpl>
  @if(notificationsEnabled) {
  <button mat-icon-button class="link" (click)="onNotificationMenuClick(notificationsMenu)">
    <mat-icon
      aria-hidden="false"
      fontIcon="notifications"
      [color]="hasUnreadNotifications() ? 'accent' : undefined"
      [matBadgeHidden]="!hasUnreadNotifications()"
      [matBadge]="notificationCount()"
    />
  </button>
  }
</ng-template>
}

<!-- Notifications Sidenav -->
<ng-template #notificationsMenu>
  <app-notifications-sidenav
    [notifications]="notifications()"
    [unreadNotifications]="filteredNotifications().unread"
    [readNotifications]="filteredNotifications().read"
    [hasNotifications]="hasNotifications()"
  />
</ng-template>

<!-- Loading -->
<ng-template #loadingTmpl>
  <div class="fx-auto fx-centered">
    <mat-spinner [diameter]="36" />
  </div>
</ng-template>

<ng-template #avatarTemplate>
  <div class="avatar-container link" [matMenuTriggerFor]="profileMatMenu">
    @if (loadingUser()) {
    <ng-template [ngTemplateOutlet]="loadingTmpl" />
    } @else {
    <ark-user-avatar [src]="user()?.photoURL" size="medium" />

    <span class="name">{{ user()?.displayName ?? 'Guest' }}</span>
    }

    <mat-icon fontIcon="arrow_drop_down" />

    <mat-menu #profileMatMenu="matMenu" xPosition="after">
      @for (item of PROFILE_MENU; track item.label) {
      <a
        mat-menu-item
        [routerLink]="item.routerLink"
        [fragment]="item.fragment"
        [disabled]="item.disabled || item.authNeeded ? !user() : false"
      >
        {{ item.label }}
      </a>
      }

      <mat-divider />

      @if (isAdminOrOwner()) {
      <a mat-menu-item [routerLink]="APP_ROUTES.Admin">Admin</a>
      }

      <button mat-menu-item (click)="onFeedbackButtonClick()">Submit Feedback</button>

      <mat-divider />

      <button mat-menu-item (click)="onAuthBtnClick(user())">
        {{ user() ? 'Log Out' : 'Log In' }}
      </button>
    </mat-menu>
  </div>
</ng-template>

@if(isMenuActive()){
<div class="overlay" (click)="isMenuActive.set(false)"></div>
}
