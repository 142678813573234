import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GthFeedbackDialogComponent } from '@gth-legacy/dialogs/feedback-dialog/feedback-dialog.component';
import { GthAnalyticsService } from '@gth-legacy/services';
import { Store } from '@ngrx/store';
import { UserService } from '@sentinels/services/firebase/user.service';
import { AuthLoad } from '@sentinels/state/features/auth/actions';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { APP_STATE } from '@sentinels/state/state';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';

import { environment } from '../../../../../../environments/environment';

export interface GthFeedback {
  feedback: string;
  rating: number;
  feedbackType: 'bug' | 'request' | 'general';
}

@Injectable({
  providedIn: 'root',
})
export class GthFeedbackService {
  user = this.store.selectSignal(selectUser);

  constructor(
    private store: Store<APP_STATE>,
    private analytics: GthAnalyticsService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
  ) {}

  openFeedbackDialog(): void {
    const dialogRef = this.dialog.open(GthFeedbackDialogComponent, {
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      width: '650px',
      maxWidth: '100%',
    });

    dialogRef.afterClosed().subscribe(async (feedback) => {
      if (!feedback) return;
      await this.submitFeedback(feedback);
      this.snackbar.open('Thank you for your feedback!');
    });
  }

  async submitFeedback(contract: GthFeedback) {
    try {
      const uri = this.getFunctionUri(`submit`);
      const callable = httpsCallableFromURL(getFunctions(), uri);

      const { feedback, feedbackType, rating } = contract;

      const result = (await callable({ feedback, feedbackType, rating })).data as any;

      this.analytics.logEvent('feedbackSubmitted', {
        score: rating,
      });

      this.userService.update(this.user().id, { feedbackSubmitted: true }).subscribe(() => {
        this.store.dispatch(AuthLoad());
      });
      return result;
    } catch (error) {
      console.error('Error:', error);
      return undefined;
    }
  }

  private getFunctionUri(functionName: string) {
    const env = this.getEnviroment();

    // eslint-disable-next-line max-len
    return `https://us-central1-gametimehero-${env}.cloudfunctions.net/feedback-triggers-${functionName}`;
  }

  private getEnviroment(): string {
    return environment.envName!;
  }
}
