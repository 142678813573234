import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  Input,
  input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { APP_STATE, AuthLogout, CurrentState } from '@gth-legacy';
import { Store } from '@ngrx/store';
import { DebuggingService } from '@sentinels/services/firebase/debugging.service';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { notificationLoad } from '@sentinels/state/features/notifications/actions';
import { notificationReducer } from '@sentinels/state/features/notifications/reducers';
import {
  getNotifications,
  getNotificationState,
} from '@sentinels/state/features/notifications/selectors';
import { APP_ROUTES } from '@shared/helpers';
import { UnsafeBrowserService } from '@shared/services/unsafe-browser.service';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { MessageService } from '../../../../features/messages/services/message.service';
import { BottomNavBarComponent } from '../components/bottom-nav-bar/bottom-nav-bar.component';
import { SmallIconProperties } from '../components/small-nav-icon/small-nav-icon.component';
import { TopNavBarComponent } from '../components/top-nav-bar/top-nav-bar.component';
import { navLayoutBottomSetState } from '../state/actions';

export interface NavBarOptions {
  pageName?: string;
  fullWidth?: boolean;
}
export interface NavMenuItem {
  label: string;
  icon: string;
  authNeeded: boolean;
  routerLink: string[];
  params?: { [key: string]: string };
  fragment?: string;
  disabled: boolean;
}

export const DISCOVER_MENU: NavMenuItem[] = [
  {
    label: 'Find People',
    icon: 'people',
    authNeeded: false,
    routerLink: [APP_ROUTES.DiscoverPlayers],
    params: { context: 'participants' },
    disabled: false,
  },
  {
    label: 'Find Communities',
    icon: 'groups',
    authNeeded: false,
    routerLink: [APP_ROUTES.DiscoverTeams],
    params: { context: 'communities' },
    disabled: false,
  },
  {
    label: 'Events',
    icon: 'event',
    authNeeded: false,
    routerLink: [APP_ROUTES.DiscoverGames],
    params: { context: 'events' },
    disabled: false,
  },
  {
    label: 'Places',
    icon: 'place',
    authNeeded: false,
    routerLink: [APP_ROUTES.DiscoverPlaces],
    params: { context: 'places' },
    disabled: false,
  },
];

export const MANAGE_MENU: NavMenuItem[] = [
  {
    label: 'My Communities',
    icon: 'group',
    authNeeded: true,
    routerLink: ['/teams-dashboard-001'],
    disabled: false,
  },
  {
    label: 'My Events',
    icon: 'games',
    authNeeded: true,
    routerLink: [APP_ROUTES.Profile],
    disabled: false,
  },
  {
    label: 'My Availability',
    icon: 'schedule',
    authNeeded: true,
    routerLink: [APP_ROUTES.Settings],
    disabled: false,
    params: { tab: 'availability' },
  },
];

export const PROFILE_MENU: NavMenuItem[] = [
  {
    label: 'Home',
    icon: 'home',
    authNeeded: true,
    routerLink: [APP_ROUTES.Home],
    disabled: false,
  },
  {
    label: 'Profile',
    icon: 'person',
    authNeeded: true,
    routerLink: [APP_ROUTES.Profile],
    disabled: false,
  },
  {
    label: 'Settings',
    icon: 'settings',
    authNeeded: true,
    routerLink: [APP_ROUTES.Settings],
    disabled: false,
  },
  {
    label: 'My Communities',
    icon: '',
    authNeeded: true,
    routerLink: [APP_ROUTES.MyCommunities],
    disabled: false,
  },
  {
    label: 'My Invoices',
    icon: 'payments',
    authNeeded: true,
    routerLink: [APP_ROUTES.Invoices],
    disabled: false,
  },
  {
    label: 'My Events',
    icon: 'calander',
    authNeeded: true,
    routerLink: [APP_ROUTES.Profile],
    disabled: false,
    fragment: 'events',
  },
];

export const CREATE_MENU: NavMenuItem[] = [
  {
    label: 'Create an Event',
    icon: 'calendar_month',
    authNeeded: true,
    routerLink: [APP_ROUTES.CreateGame],
    disabled: false,
  },
  {
    label: 'Create a Community',
    icon: 'group',
    authNeeded: true,
    routerLink: [APP_ROUTES.CreateTeam],
    disabled: false,
  },
  {
    label: 'Create an Invoice',
    icon: 'monetization_on',
    authNeeded: true,
    routerLink: [APP_ROUTES.Invoices],
    disabled: false,
  },
  {
    label: 'Create a Place',
    icon: 'place',
    authNeeded: true,
    routerLink: [APP_ROUTES.PlacesNew],
    disabled: false,
  },
];

@Component({
  selector: 'app-nav-bar-layout',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    TopNavBarComponent,
    BottomNavBarComponent,
    AsyncPipe,
  ],
})
export class NavBarLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('bottomBar', { static: true }) bottomBar: BottomNavBarComponent;
  @ViewChild('topNavBar', { read: ElementRef }) topNavBar!: ElementRef;
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;
  @Input()
  showTopBar = true;

  @Input()
  showBottomBar = false;

  protected readonly APP_ROUTES = APP_ROUTES;

  userPhotoError?: ErrorEvent;

  isBrowserUnSafe = false;
  notifications = this.store.selectSignal(getNotifications);
  notificationState = this.store.selectSignal(getNotificationState);
  user = this.store.selectSignal(selectUser);
  userIsLoading = this.store.selectSignal((state) => {
    return state?.authFeature?.state === CurrentState.Loading;
  });

  @Input() navBarOptions: NavBarOptions = {
    pageName: 'Unknown',
    fullWidth: false,
  };
  numUnreadMessages$?: Observable<number>;

  isAdminOrOwner = signal(false);

  iconProps: SmallIconProperties[] = [
    {
      name: 'Home',
      url: APP_ROUTES.Profile,
      image: 'home',
      fragment: 'profile-personality',
    },

    {
      name: 'Find...',
      url: APP_ROUTES.DiscoverGames,
      image: 'discover',
    },

    {
      name: 'My Communities',
      url: APP_ROUTES.Teams,
      image: 'teams-groups',
    },

    {
      name: 'My Events',
      url: APP_ROUTES.Profile,
      image: 'my-events',
      fragment: 'profile-passions',
    },
    {
      name: 'My Availability',
      url: APP_ROUTES.Settings,
      image: 'my-availibility',
      params: { tab: 'availability' },
    },

    {
      name: 'Messages',
      url: APP_ROUTES.Messages,
      image: 'messages',
    },
  ];

  private subscriptions = new Subscription();

  constructor(
    private debuggingService: DebuggingService,
    private safeStorage: SrvSafeStorageService,
    private safeBrowser: UnsafeBrowserService,
    public router: Router,
    private messageService: MessageService,
    private store: Store<APP_STATE>,
  ) {
    effect(
      async () => {
        const user = this.user();

        if (!user) return;

        const notificationState = this.notificationState();

        if (notificationState === CurrentState.Pending) {
          this.store.dispatch(notificationLoad({ userId: user.uid }));
        }

        const roles = await this.debuggingService.getUserRoles(user.id);
        const isAdmin = roles.includes('admin') || roles.includes('owner');

        const storedTimestamp = this.safeStorage.getItem('heroAdminTimestamp');

        const isAdminOrOwner = storedTimestamp ? true : isAdmin;

        this.isAdminOrOwner.set(isAdminOrOwner);
      },
      { allowSignalWrites: true },
    );
  }

  async ngOnInit() {
    this.store.addReducer('notifications', notificationReducer);

    this.numUnreadMessages$ = this.messageService.getNonTeamConversations$().pipe(
      map((conversations) => {
        let n = 0;
        for (const conversation of conversations) {
          conversation.messages.forEach((m) => (!m.isRead ? n++ : undefined));
        }
        return n;
      }),
    );

    const storedTimestamp = this.safeStorage.getItem('heroAdminTimestamp');

    if (storedTimestamp) {
      const storedTime = parseInt(storedTimestamp, 10);
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - storedTime;
      const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

      if (timeDifference < twentyFourHoursInMilliseconds) {
        this.isAdminOrOwner.set(true);
      } else {
        this.safeStorage.removeItem('heroAdminTimestamp');
        this.isAdminOrOwner.set(false);
      }
    }

    this.subscriptions.add(
      this.safeBrowser.unsafe$.subscribe((unsafe) => {
        this.isBrowserUnSafe = unsafe;
      }),
    );
  }

  ngAfterViewInit(): void {
    if (this.bottomBar) {
      const sub$ = this.bottomBar.isHidden.pipe(
        distinctUntilChanged(),
        map((status) => {
          this.store.dispatch(navLayoutBottomSetState({ isCollapsed: status }));
        }),
      );

      this.subscriptions.add(sub$.subscribe());
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onLogout() {
    if (!this.user()) return;
    this.store.dispatch(AuthLogout());
  }
  onLogin() {
    this.router.navigate([APP_ROUTES.Login]);
  }

  // onRefreshTeams() {
  //   this.notificationsService.refreshTeams();
  // }

  // onNotificationsRefresh() {
  //   this.notificationsService.refresh();
  // }
}
