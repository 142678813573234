<h1 mat-dialog-title>Give us your feedback</h1>
<div mat-dialog-content class="content">
  <p>
    On a scale of 0 to 10, how likely are you to recommend our business to a friend or
    colleague?
  </p>

  <div class="flex justify-center flex-nowrap gap-3 mb-6">
    @for (rating of ratings; track rating) {
      <div
        (click)="selectedRating = rating"
        class="rating"
        [ngClass]="{ selected: selectedRating === rating }"
        matRipple
      >
        {{ rating }}
      </div>
    }
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Your Feedback</mat-label>
    <textarea matInput [(ngModel)]="feedback"></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Feedback Type</mat-label>
    <mat-select [(ngModel)]="feedbackType">
      <mat-option value="general">General Feedback</mat-option>
      <mat-option value="bug">Bug</mat-option>
      <mat-option value="request">Feature Request</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="actions">
  <span class="fx-auto"></span>
  <button mat-button cdkFocusInitial class="uppercase" (click)="onCancelButtonClick()">
    Cancel
  </button>
  <button mat-flat-button color="primary" class="uppercase" (click)="onSubmitButtonClick()">
    Submit Feedback/Review
  </button>
</div>
